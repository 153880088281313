import React from 'react';
import '../components/Card.css'; 
import githubLogo from '../assets/Githublogo.jpg'; 
import Image1 from "../assets/Sql_t5.jpeg"
import Image2 from "../assets/Yolov5.png"
import Image3 from "../assets/Plot.png"
import Image4 from "../assets/ui1.png"
import Image5 from "../assets/Warehouse.jpg"

const Projects = () => {
  const projects = [
    {
      id: 1,
      title: 'SQL Generator T5',
      description: 'A fine-tuned t5-small model trained to generate SQL queries from natural language text.',
      skills:'Skills: HuggingFace transformers, PyTorch, LLM, Natural Language Processing',
      imageUrl: Image1,
      githubUrl: 'https://github.com/Jahnavi149/SQL_generator_t5-small',
    },
    {
      id: 2,
      title: 'Surgical Tool Detector',
      description: 'A deep learning model built on YOLOv5 architecture, combined with formal run-time monitoring methods can assist surgeons during laparoscopic surgery.',
      skills:'Skills: Computer Vision, YOLOv5, CNN, PyTorch',
      imageUrl: Image2,
      githubUrl: 'https://github.com/Jahnavi149/Surgical-Tool-Detection-using-YOLOv5',
    },
    {
      id: 3,
      title: 'Stock Price Predictor',
      description: 'A machine learning model to predict future stock price trends based on historical data.',
      skills:'Skills: Recurrent Neural Networks, LSTM, Python',
      imageUrl: Image3,
      githubUrl: 'https://github.com/Jahnavi149/Google-Stock-Price-Prediction',
    },
    {
      id: 4,
      title: 'Food Recipe App',
      description: 'A food recipe app built using React Native and Tailwind CSS that contains recipes of many items grouped by categories.',
      skills:'Skills: React Native, TailwindCSS, JavaScript, Microsoft Axure',
      imageUrl: Image4,
      githubUrl: 'https://github.com/Jahnavi149/Food-Recipe-App',
    },
    {
      id: 5,
      title: 'Automated Warehousing',
      description: 'Implemented automated warehouse scenario using logic programming (Clingo). The model has successfully passed all dynamic world instances provided in the ASP challenge. The model is optimized to fulfill all orders in minimum amount of time possible.',
      skills:'Skills: Clingo, Answer Set Programming',
      imageUrl: Image5,
      githubUrl: 'https://github.com/Jahnavi149/Automated-Warehousing-using-Clingo',
    },
    // Add more projects as needed
  ];

  return (
    <div name="projects" className="flex flex-col items-center py-20 justify-center w-full bg-gradient-to-b from-gray-800 to-black text-white">
      <div className="pb-8 text-center p-5">
        <p className="text-4xl text-center font-bold inline border-b-4 border-gray-500">
          Projects
        </p>
      </div>
      <div className="flex flex-wrap justify-center">
        {projects.map(({ id, title, description, skills, imageUrl, githubUrl }) => (
          <div key={id} className="card mx-4 my-4 p-5 min-w-30">
            <div className="image-container" style={{ height: '200px', overflow: 'hidden' }}>
              <img src={imageUrl} alt={title} className="card-image" style={{ width: '100%', height: 'auto' }} />
            </div>
            <div className="card-content">
              <div className="card-header">
                <h2 className="card-title" style={{flex : 1}}>{title}</h2>
                <a
                  href={githubUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="github-link"
                >
                  <img src={githubLogo} alt="GitHub" className="github-logo" />
                </a>
              </div>
              <p className="card-description">{description}</p>
              <p className="card-description font-bold">{skills}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );  
};

export default Projects;
