import React from 'react'
import { GrMail } from "react-icons/gr";
import { SiLinkedin, SiLeetcode, SiGithub} from "react-icons/si";

const SocialLinks = () => {

  const links = [
      {
        id: 1,
        child: (
            <>
            Mail <GrMail size={30}/>
            </>
        ),
        href: "mailto:jlavu@asu.edu",
        style: "rounded-tr-md"
      },
      {
        id: 2,
        child: (
            <>
            LinkedIn <SiLinkedin size={30}/>
            </>
        ),
        href: "https://www.linkedin.com/in/jahnavi-sri-sai-b8a5851b0/",
      },
      {
        id: 3,
        child: (
            <>
            LeetCode <SiLeetcode size={30}/>
            </>
        ),
        href: "https://leetcode.com/happybunny24/",
      },
      {
        id: 4,
        child: (
            <>
            GitHub <SiGithub size={30}/>
            </>
        ),
        href: "https://github.com/Jahnavi149",
        style: "rounded-br-md"
      }
    ];

  return (
    <div className='hidden lg:flex flex-col top-[35%] left-0 fixed'>
        <ul>
            {links.map(({id, child, href, style, download}) => (
              <li key={id} className={'flex justify-between items-center w-40 h-14 px-4 bg-gray-600 ml-[-100px] hover:ml-[-10px] hover:rounded-md' + style}>
              <a href={href}
                 className='flex justify-between items-center w-full text-white'
                 >
                 {child}
              </a>
          </li>
           ))}   
        </ul>
    </div>
  );
};

export default SocialLinks;