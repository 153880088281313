import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import NavLinks from "./NavLinks";

const NavBar = () => {
  const [nav, setNav] = useState(false);

  const links = [
    {
      id: 1,
      link: "home",
    },
    {
      id: 2,
      link: "about",
    },
    {
      id: 3,
      link: "projects",
    },
  ];

  return (
    <div className="flex justify-between items-center w-full h-20 px-4 bg-black text-white fixed">
      <div>
        <h1 className="flex text-5xl py-10 ml-2">Jahnavi Sri Sai</h1>
      </div>

      <ul className="hidden md:flex">
        {links.map(({ id, link }) => (
          <NavLinks
            key={id}
            link={link}
            className="px-4 cursor-pointer capitalize font-medium text-gray-400 hover:scale-105 duration-200"
          />
        ))}
        <li className="px-4 cursor-pointer capitalize font-medium text-gray-400 hover:scale-105 duration-200">
          <a href="/resume.pdf">
            Resume
          </a>
        </li>
      </ul>

      <div
        onClick={() => setNav(!nav)}
        className="cursor-pointer pr-4 md:hidden z-10 text-gray-500"
      >
        {nav ? <FaTimes size={30} /> : <FaBars size={30} />}
      </div>

      {nav && (
        <ul className="flex flex-col justify-center items-center absolute top-0 left-0 w-full h-screen bg-gradient-to-b from-black to-gray-800 text-gray-500">
          {links.map(({ id, link }) => (
          <NavLinks
            onClick={() => setNav(!nav)}
            key={id}
            link={link}
            className="px-4 p-4 cursor-pointer capitalize text-4xl font-medium text-gray-400 hover:scale-105 duration-200"
          />
        ))}
        <li className="px-4 p-4 cursor-pointer capitalize text-4xl font-medium text-gray-400 hover:scale-105 duration-200">
          <a href="/resume.pdf">
            Resume
          </a>
        </li>
        </ul>
      )}
    </div>
  );
};

export default NavBar;