import React from "react";
import myImage from "../assets/myImage.jpg";

const About = () => {
  return (
    <div
      name="about"
      className="flex flex-col items-center py-20 justify-center w-full bg-gradient-to-b from-gray-800 to-black text-white"
    >
      <div className="max-w-screen-lg p-4 mx-auto flex flex-col lg:flex-row justify-center items-center">
        <div className="lg:w-5/6 lg:pr-9 pb-7">
          <p className="text-4xl font-bold inline border-b-4 border-gray-500">
            About
          </p>
          <p className="text-xl mt-20">
            My name is Jahnavi Sri Sai. I am currently pursuing a Master's degree in Computer Science at Arizona State University in the United States.
            My passion lies in constructing <strong>Machine Learning</strong> models and honing their abilities to accomplish targeted objectives.
            I earned my Bachelor's degree in Computer Science from the esteemed institution, <strong>IIT Bhubaneswar</strong>.
          </p>
          <br />
          <p className="text-xl">
            I have enriched my professional experience through an internship at <strong>Goldman Sachs</strong>, where I gained hands-on experience in <strong>Java programming</strong> and proficiency in <strong>JUnit testing</strong> methodologies.
            Prior to my current endeavors, I devoted a year to research at IIT Bhubaneswar, focusing on <strong>Computer Vision</strong> and the refinement of Machine Learning models to enhance the speed and accuracy of <strong>object-detection</strong>.
          </p>
          <br />
          <p className="text-xl">
            In addition to machine learning, I also find joy in creating development projects using <strong>React Native</strong> and <strong>React.js</strong>.
            I particularly enjoy experimenting with various UI designs and bringing them to life through development.
          </p>
          <br />
          <p className="text-xl">
            Beyond ML and development, I have a deep passion for problem-solving and logic. I am proficient in <strong>C++, Data Structures and Algorithms</strong>.
            My early fascination with mathematics and logic served as the initial inspiration for my journey into the tech field.
          </p>
        </div>
        <div className="w-1/3">
          <img
            src={myImage}
            alt="my profile"
            className="mx-auto w-auto h-auto"
            style={{objectFit:"cover", objectPosition:"top"}}
          />
        </div>
      </div>
    </div>
  );
};

export default About;
