import React, { useState, useEffect, useMemo } from "react";

const Home = () => {
  const [lineIndex, setLineIndex] = useState(0);
  const [charIndex, setCharIndex] = useState(0);
  
  // Memoize the 'lines' array to prevent it from changing on every render
  const lines = useMemo(() => [
    "Software Engineer..",
    "ML Engineer..",
    "Data Scientist.."
  ], []);
  
  useEffect(() => {
    const interval = setInterval(() => {
      // Increment character index
      setCharIndex((prevIndex) => prevIndex + 1);

      // If all characters are printed, move to next line or start over
      if (charIndex === lines[lineIndex].length) {
        if (lineIndex === lines.length - 1) {
          setLineIndex(0);
        } else {
          setLineIndex((prevIndex) => prevIndex + 1);
        }
        setCharIndex(0);
      }
    }, 100);

    // Cleanup interval
    return () => clearInterval(interval);
  }, [lineIndex, charIndex, lines]); // Include 'lines' in the dependency array

  // Get the current text to display
  const currentText = lines[lineIndex].slice(0, charIndex);

  return (
    <div
      name="home"
      className="flex flex-col justify-center w-full min-h-screen bg-gradient-to-b from-black via-black to-gray-800"
    >
      <div className="flex flex-col max-w-screen-lg mx-auto md:flex-row items-center justify-center px-4" style={{ paddingTop: "100px" }}>
        <div className="flex flex-col justify-center h-full w-40%">
          <h2 className="text-4xl pb-9 pr-6 sm:text-7xl font-bold text-white animate-alternate">
            Welcome to my world!
          </h2>
          <h2 className="text-3xl pb-9 pr-6 sm:text-4xl font-bold text-blue-600">Actively looking for Internship opportunity starting May 2024 as {currentText}</h2>
          <p className="text-gray-500 pt-4 max-w-md">
            MSCS at Arizona State University.
          </p>
          <p className="text-gray-500 pt-4 max-w-md">
            Summer Internship at Goldman Sachs.
          </p>
          <p className="text-gray-500 pt-4 max-w-md">
            Bachelors at IIT Bhubaneswar.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Home;
